<template>
  <div>
    <v-card>
      <v-data-table
        disable-filtering
        disable-sort
        hide-default-footer
        :headers="headers"
        :items="items"
        :items-per-page="itemsPerPage"
        :loading="loadingItens"
        :page.sync="page"
        show-expand
        single-expand
        checkbox-color="#395764"
      >
        <template v-slot:[`item.pedido`]="{ item }">
          <span :class="item.status_sapiens === 5 ? 'text-decoration-line-through' : ''">
            {{ item.pedido }}
          </span>
        </template>
        <template v-slot:[`item.nota_fiscal`]="{ item }">
          <span :class="item.status_sapiens === 5 ? 'text-decoration-line-through' : ''">
            {{ item.nota_fiscal }}
          </span>
        </template>
        <template v-slot:[`item.data_emissao`]="{ item }">
          <span :class="item.status_sapiens === 5 ? 'text-decoration-line-through' : ''">
            {{ item.data_emissao | moment('DD/MM/YYYY') }}
          </span>
        </template>
        <template v-slot:[`item.data_previsao`]="{ item }">
          <span :class="item.status_sapiens === 5 ? 'text-decoration-line-through' : ''">
            {{ item.data_previsao | moment('DD/MM/YYYY') }}
          </span>
        </template>
        <template v-slot:[`item.data_integracao`]="{ item }">
          <span :class="item.status_sapiens === 5 ? 'text-decoration-line-through' : ''">
            {{ item.data_integracao | moment('DD/MM/YYYY') }}
          </span>
        </template>
        <template v-slot:[`item.empresa`]="{ item }">
          <span :class="item.status_sapiens === 5 ? 'text-decoration-line-through' : ''">
            {{ item.empresa }}
          </span>
        </template>
        <template v-slot:[`item.producao`]="{ item }">
          <span :class="item.status_sapiens === 5 ? 'text-decoration-line-through' : ''">
            {{ item.producao }}
          </span>
        </template>
        <template v-slot:[`item.cliente.nome`]="{ item }">
          <span :class="item.status_sapiens === 5 ? 'text-decoration-line-through' : ''">
            {{ item.cliente.nome }}
          </span>
        </template>
        <template v-slot:[`item.cliente.telefone`]="{ item }">
          <span :class="item.status_sapiens === 5 ? 'text-decoration-line-through' : ''">
            {{ item.cliente.telefone }}
          </span>
        </template>
        <template v-slot:[`item.cliente.email`]="{ item }">
          <span :class="item.status_sapiens === 5 ? 'text-decoration-line-through' : ''">
            {{ item.cliente.email }}
          </span>
        </template>
        <template v-slot:[`item.delivery_type`]="{ item }">
          <span :class="item.status_sapiens === 5 ? 'text-decoration-line-through' : ''">
            {{ item.delivery_type === 2 ? 'Entrega' : 'Retira' }}
          </span>
        </template>
        <template v-slot:[`item.quantidade_carga`]="{ item }">
          <span :class="item.status_sapiens === 5? 'text-decoration-line-through' : ''">{{ $stringFormat.formatNumber(item.quantidade_carga, 0, 0) }}</span>
        </template>
        <template v-slot:[`item.valor_carga`]="{ item }">
          <span :class="item.status_sapiens === 5 ? 'text-decoration-line-through' : ''"> R$ {{ $stringFormat.formatNumber(item.valor_carga, 0, 0) }}</span>
        </template>
        <template v-slot:[`item.status_roteirizacao`]="{ item }">
          <v-chip :color="getRouteStatusStyle(item.routeasy_order_event ? item.routeasy_order_event.status : undefined).color " dark label small>
            <strong>
              {{ getRouteStatusStyle(item.routeasy_order_event ? item.routeasy_order_event.status : undefined).text }}
            </strong>
          </v-chip>
        </template>
        <template v-slot:[`item.status_integracao`]="{ item }">
          <v-chip
              v-if="item.status_integracao"
              color="green"
              dark
              label
              small
          >
            <strong>Integrado</strong>
          </v-chip>
          <v-chip
              v-else-if="!item.status_integracao && item.data_integracao"
              color="red"
              dark
              label
              small
          >
            <strong>Erro</strong>
          </v-chip>
          <v-chip
              v-else-if="!item.status_integracao && !item.data_integracao"
              color="blue"
              dark
              label
              small
          >
            <strong>Pendente</strong>
          </v-chip>
          <v-chip
              v-else
              color="grey lighten-2"
              dark
              label
              small
          >
            <strong>Não Identificado</strong>
          </v-chip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-card flat tile class="transparent my-4">
              <v-card-text>
                <strong>Endereço</strong> <br><br>
                <span v-if="item.cliente.linha_endereco">
                  <strong>Linha Endereço: </strong> {{ item.cliente.linha_endereco }}<br>
                </span>
                <strong>Logradouro: </strong> {{ item.cliente.logradouro }}, {{ item.cliente.numero }} - {{ item.cliente.complemento }} <br>
                <strong>Bairro: </strong> {{ item.cliente.bairro }} <br>
                <strong>Cidade: </strong> {{ item.cliente.cidade }} - {{ item.cliente.uf }} <br>
                <strong>CEP: </strong> {{ item.cliente.cep }} <br>
              </v-card-text>
              <v-card-text v-if="item.cliente.linha_lat_long">
                <strong>Coordenadas</strong> <br> <br>
                <strong>Linha Coordenada: </strong> {{ item.cliente.linha_lat_long }} <br>
                <strong>Lat: </strong> {{ item.cliente.lat }}, <strong>Long: </strong> {{ item.cliente.long }} <br>
              </v-card-text>
              <v-card-text>
                <v-row dense>
                  <v-col md="6">
                    <strong>Informações do Pedido</strong> <br><br>
                    {{ item.informacoes_adicionais }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon :disabled="item.routeasy_order_events.length > 0 || item.delivery_type === 1" small @click="openEditItemDialog(item)">mdi-map-marker-path</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-card color="transparent" flat>
      <v-card-actions>
        <v-spacer />
        <v-pagination
            v-model="page"
            :length="pageCount"
        />
        <v-spacer />
      </v-card-actions>
    </v-card>
    <custom-snackbar ref="snackbar"/>
    <editar-pedido-routeasy-component ref="pedidoDialog" @save="updateEditedItem" @close="closeEditItemDialog"/>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import EditarPedidoRouteasyComponent from "@/view/pages/fretes/routeasy/components/EditarPedidoRouteasyComponent";

export default {
  components: {EditarPedidoRouteasyComponent},
  props: {
    itemsPerPage: {
      type: Number,
    },
    filtros: {
      type: Object,
    },
    headers: {
      type: Object,
    },
  },

  data: () => ({
    items: [],
    itemsLength: 0,
    page: 1,
    pageCount: 0,
    loadingItens: true,
    editedIndex: -1,
    editedItem: [],
  }),

  watch: {
    page () {
      this.pegarSimulacoes()
    },

    itemsPerPage () {
      this.pegarSimulacoes(true)
    },

    filtros () {
      this.$router.push({ query: this.filtros, silent: true }).catch((e) => e)
      this.pegarSimulacoes(true)
    },
  },

  methods: {
    async pegarSimulacoes (reiniciarPaginacao = false) {
      this.loadingItens = true
      this.items = []
      this.itemsLength = 0
      this.pageCount = 1

      try {
        const params = {
          itemsPerPage: this.itemsPerPage,
          page: reiniciarPaginacao ? 1 : this.page,
          ...this.filtros,
        }

        const result = (await ApiService.get('fretes/routeasy/pedidos', params)).data

        this.items = result.data
        this.itemsLength = result.meta.total
        this.pageCount = result.meta.last_page
      } catch (e) {
        this.$refs.snackbar.show(
          'Erro',
          'Não foi carregar as informações.',
          'danger',
        )
      }

      this.loadingItens = false
    },

    sumQuantidadeTotalKm (items) {
      let sum = 0

      items.forEach((item) => {
        sum += item.simulacao.distancia
      })

      return sum
    },

    sumQuantidadeTotalM2 (items) {
      let sum = 0

      items.forEach((item) => {
        sum += item.simulacao.quantidade
      })

      return sum
    },

    sumValorTotalKm (items) {
      let sum = 0

      items.forEach((item) => {
        sum += item.simulacao.valorTotalKm
      })

      return sum
    },

    sumValorTotalM2 (items) {
      let sum = 0

      items.forEach((item) => {
        sum += item.simulacao.valorTotalM2
      })

      return sum
    },

    getRouteStatusStyle(status) {
      switch (status) {
        case 'completed':
          return {
            text: 'Entregue',
            color: 'green',
          }

        case 'servicing':
          return {
            text: 'Descarregando',
            color: 'deep-orange',
          }

        case 'arrived':
          return {
            text: 'No local',
            color: 'amber',
          }

        case 'on_route':
          return {
            text: 'Em trânsito',
            color: 'purple',
          }

        case 'on_hold':
          return {
            text: 'Roteirizado',
            color: 'blue',
          }

        default:
          return {
            text: 'Pendente',
            color: 'grey',
          }
      }
    },

    openEditItemDialog (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.$refs.pedidoDialog.open(item.id)
    },

    closeEditItemDialog () {
      this.editedItem = {}
      this.editedIndex = -1
    },

    updateEditedItem (event) {
      this.editedItem.routeasy_order_event = {
        status: event.status,
        date: event.date,
      }

      Object.assign(this.items[this.editedIndex], this.editedItem)
    }
  },

  mounted () {
    this.pegarSimulacoes(true)
  },
}
</script>
