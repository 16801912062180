import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDataTable,{attrs:{"disable-filtering":"","disable-sort":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"loading":_vm.loadingItens,"page":_vm.page,"show-expand":"","single-expand":"","checkbox-color":"#395764"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.pedido",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status_sapiens === 5 ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(item.pedido)+" ")])]}},{key:"item.nota_fiscal",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status_sapiens === 5 ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(item.nota_fiscal)+" ")])]}},{key:"item.data_emissao",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status_sapiens === 5 ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(_vm._f("moment")(item.data_emissao,'DD/MM/YYYY'))+" ")])]}},{key:"item.data_previsao",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status_sapiens === 5 ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(_vm._f("moment")(item.data_previsao,'DD/MM/YYYY'))+" ")])]}},{key:"item.data_integracao",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status_sapiens === 5 ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(_vm._f("moment")(item.data_integracao,'DD/MM/YYYY'))+" ")])]}},{key:"item.empresa",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status_sapiens === 5 ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(item.empresa)+" ")])]}},{key:"item.producao",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status_sapiens === 5 ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(item.producao)+" ")])]}},{key:"item.cliente.nome",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status_sapiens === 5 ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(item.cliente.nome)+" ")])]}},{key:"item.cliente.telefone",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status_sapiens === 5 ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(item.cliente.telefone)+" ")])]}},{key:"item.cliente.email",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status_sapiens === 5 ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(item.cliente.email)+" ")])]}},{key:"item.delivery_type",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status_sapiens === 5 ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(item.delivery_type === 2 ? 'Entrega' : 'Retira')+" ")])]}},{key:"item.quantidade_carga",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status_sapiens === 5? 'text-decoration-line-through' : ''},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.quantidade_carga, 0, 0)))])]}},{key:"item.valor_carga",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status_sapiens === 5 ? 'text-decoration-line-through' : ''},[_vm._v(" R$ "+_vm._s(_vm.$stringFormat.formatNumber(item.valor_carga, 0, 0)))])]}},{key:"item.status_roteirizacao",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.getRouteStatusStyle(item.routeasy_order_event ? item.routeasy_order_event.status : undefined).color,"dark":"","label":"","small":""}},[_c('strong',[_vm._v(" "+_vm._s(_vm.getRouteStatusStyle(item.routeasy_order_event ? item.routeasy_order_event.status : undefined).text)+" ")])])]}},{key:"item.status_integracao",fn:function(ref){
var item = ref.item;
return [(item.status_integracao)?_c(VChip,{attrs:{"color":"green","dark":"","label":"","small":""}},[_c('strong',[_vm._v("Integrado")])]):(!item.status_integracao && item.data_integracao)?_c(VChip,{attrs:{"color":"red","dark":"","label":"","small":""}},[_c('strong',[_vm._v("Erro")])]):(!item.status_integracao && !item.data_integracao)?_c(VChip,{attrs:{"color":"blue","dark":"","label":"","small":""}},[_c('strong',[_vm._v("Pendente")])]):_c(VChip,{attrs:{"color":"grey lighten-2","dark":"","label":"","small":""}},[_c('strong',[_vm._v("Não Identificado")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VCard,{staticClass:"transparent my-4",attrs:{"flat":"","tile":""}},[_c(VCardText,[_c('strong',[_vm._v("Endereço")]),_vm._v(" "),_c('br'),_c('br'),(item.cliente.linha_endereco)?_c('span',[_c('strong',[_vm._v("Linha Endereço: ")]),_vm._v(" "+_vm._s(item.cliente.linha_endereco)),_c('br')]):_vm._e(),_c('strong',[_vm._v("Logradouro: ")]),_vm._v(" "+_vm._s(item.cliente.logradouro)+", "+_vm._s(item.cliente.numero)+" - "+_vm._s(item.cliente.complemento)+" "),_c('br'),_c('strong',[_vm._v("Bairro: ")]),_vm._v(" "+_vm._s(item.cliente.bairro)+" "),_c('br'),_c('strong',[_vm._v("Cidade: ")]),_vm._v(" "+_vm._s(item.cliente.cidade)+" - "+_vm._s(item.cliente.uf)+" "),_c('br'),_c('strong',[_vm._v("CEP: ")]),_vm._v(" "+_vm._s(item.cliente.cep)+" "),_c('br')]),(item.cliente.linha_lat_long)?_c(VCardText,[_c('strong',[_vm._v("Coordenadas")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_c('strong',[_vm._v("Linha Coordenada: ")]),_vm._v(" "+_vm._s(item.cliente.linha_lat_long)+" "),_c('br'),_c('strong',[_vm._v("Lat: ")]),_vm._v(" "+_vm._s(item.cliente.lat)+", "),_c('strong',[_vm._v("Long: ")]),_vm._v(" "+_vm._s(item.cliente.long)+" "),_c('br')]):_vm._e(),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"md":"6"}},[_c('strong',[_vm._v("Informações do Pedido")]),_vm._v(" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(item.informacoes_adicionais)+" ")])],1)],1)],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"disabled":item.routeasy_order_events.length > 0 || item.delivery_type === 1,"small":""},on:{"click":function($event){return _vm.openEditItemDialog(item)}}},[_vm._v("mdi-map-marker-path")])]}}],null,true)})],1),_c(VCard,{attrs:{"color":"transparent","flat":""}},[_c(VCardActions,[_c(VSpacer),_c(VPagination,{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c(VSpacer)],1)],1),_c('custom-snackbar',{ref:"snackbar"}),_c('editar-pedido-routeasy-component',{ref:"pedidoDialog",on:{"save":_vm.updateEditedItem,"close":_vm.closeEditItemDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }