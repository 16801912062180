<template>
  <div>
    <v-toolbar flat class="d-flex flex-row-reverse pb-5" color="rgba(0,0,0,0)">
      <v-btn
          color="primary"
          dark
          class="mr-3"
          title="Remover Filtros"
          @click="$router.push('/fretes/routeasy/operadores')"
      >
        <v-icon>mdi-account-hard-hat</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        dark
        class="mr-3"
        title="Remover Filtros"
        @click="removeFiltros"
    >
      <v-icon>mdi-filter-remove</v-icon>
    </v-btn>
      <HeaderControl
          v-model="model"
          :headers="headersOptions"
          :items-per-page="itemsPerPage"
          show-items-per-page
          local-storage-save-name="route-easy"
          @itemsPerPage="$emit('itemsPerPage', $event)"
      ></HeaderControl>
    </v-toolbar>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
    },
    filtro: {
      type: Object,
    },
    itemsPerPage: {
      type: Number,
    },
  },
  data: () => ({
    headers: [],
    headersOptions: [{
      value: 'data-table-expand',
      align: 'right',
      class: 'primary rounded-tl',
    }, {
      align: 'center',
      text: 'Pedido',
      value: 'pedido',
      ativado: true,
      class: 'primary white--text',
    }, {
      align: 'center',
      text: 'Nota Fiscal',
      value: 'nota_fiscal',
      class: 'primary white--text',
      ativado: true,
    }, {
      align: 'center',
      text: 'Integração',
      value: 'data_integracao',
      class: 'primary white--text',
      ativado: true,
    }, {
      align: 'center',
      text: 'Emissão',
      value: 'data_emissao',
      class: 'primary white--text',
      ativado: true,
    }, {
      align: 'center',
      text: 'Previsão',
      value: 'data_previsao',
      class: 'primary white--text',
      ativado: true,
    }, {
      align: 'left',
      text: 'Empresa',
      value: 'empresa',
      class: 'primary white--text',
      ativado: false,
    }, {
      align: 'left',
      text: 'Produção',
      value: 'producao',
      class: 'primary white--text',
      ativado: true,
    }, {
      align: 'left',
      text: 'Cliente',
      value: 'cliente.nome',
      class: 'primary white--text',
      ativado: true,
    }, {
      align: 'left',
      text: 'Telefone',
      value: 'cliente.telefone',
      class: 'primary white--text',
      ativado: false,
    }, {
      align: 'left',
      text: 'E-mail',
      value: 'cliente.email',
      class: 'primary white--text',
      ativado: false,
    }, {
      align: 'center',
      text: 'Tipo Frete',
      value: 'delivery_type',
      class: 'primary white--text',
      ativado: false,
    }, {
      align: 'center',
      text: 'Quantidade',
      value: 'quantidade_carga',
      class: 'primary white--text',
      ativado: true,
    }, {
      align: 'center',
      text: 'Valor',
      value: 'valor_carga',
      class: 'primary white--text',
      ativado: true,
    }, {
      align: 'center',
      text: 'Roteirização',
      value: 'status_roteirizacao',
      class: 'primary white--text',
      obrigatorio: true,
      width: '0',
    }, {
      align: 'center',
      text: 'Integração',
      value: 'status_integracao',
      class: 'primary white--text',
      obrigatorio: true,
      width: '0',
    }, {
      align: 'center',
      text: '',
      value: 'actions',
      class: 'rounded-tr primary white--text',
      obrigatorio: true,
      width: '0',
    }],
  }),
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },

  methods: {
    removeFiltros () {
      this.$router.push({ query: null })
      window.location.reload()
    },
  },
}
</script>
