<template>
  <div>
    <FiltroMobile :has-change="change" @search="seachFilter">
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-select
            v-model="tipoDataId"
            dense
            :items="select.tipoData"
            item-text="descricao"
            item-value="id"
            label="Tipo da Data"
            persistent-placeholder
            outlined
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <DatePicker
            v-model="dataInicial"
            clearable
            label="Data Inicial"
            persistent-placeholder
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <DatePicker
            v-model="dataFinal"
            clearable
            label="Data Final"
            persistent-placeholder
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <empresas-select
            v-model="empresaId"
            clearable
            persistent-placeholder
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
      <producoes-select
          v-model="producaoId"
          clearable
          :empresa="empresaId"
          multiple
          persistent-placeholder
          @change="change = true"
      />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-select
            v-model="statusIntegracaoId"
            clearable
            dense
            :items="select.statusIntegracao"
            item-text="descricao"
            item-value="id"
            label="Status Integração"
            persistent-placeholder
            outlined
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-select
            v-model="statusRouteasyId"
            clearable
            dense
            :items="select.statusRouteasy"
            item-text="descricao"
            item-value="id"
            label="Status Routeasy"
            persistent-placeholder
            outlined
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-text-field
            v-model="notaFiscal"
            clearable
            dense
            label="Nota Fiscal"
            outlined
            persistent-placeholder
            @click:clear="change = true"
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-text-field
            v-model="pedido"
            clearable
            dense
            label="Pedido"
            outlined
            persistent-placeholder
            @click:clear="change = true"
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-select
            v-model="statusPedidoId"
            clearable
            dense
            :items="select.statusPedido"
            item-text="descricao"
            item-value="id"
            label="Status Pedido"
            persistent-placeholder
            outlined
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-select
            v-model="deliveryTypeId"
            clearable
            dense
            :items="select.deliveryType"
            item-text="descricao"
            item-value="id"
            label="Tipo Frete"
            persistent-placeholder
            outlined
        />
      </v-col>
      <template v-slot:outros>
      </template>
    </FiltroMobile>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
  data: () => ({
    change: false,
    pedido: null,
    notaFiscal: null,
    empresaId: null,
    producaoId: null,
    tipoDataId: null,
    dataInicial: null,
    dataFinal: null,
    statusIntegracaoId: null,
    statusRouteasyId: null,
    statusPedidoId: null,
    deliveryTypeId: null,
    select: {
      tipoData: [{
        id: 1,
        descricao: 'Data Integração',
      }, {
        id: 2,
        descricao: 'Data Emissão',
      }, {
        id: 3,
        descricao: 'Data Prevista',
      }],
      statusIntegracao: [{
        id: 1,
        descricao: 'Erro',
      }, {
        id: 2,
        descricao: 'Pendente',
      }, {
        id: 3,
        descricao: 'Integrado',
      }],
      statusRouteasy: [{
        id: 'pending',
        descricao: 'Pendente',
      }, {
        id: 'on_hold',
        descricao: 'Roteirizado',
      }, {
        id: 'on_route',
        descricao: 'Em trânsito',
      }, {
        id: 'arrived',
        descricao: 'No local',
      }, {
        id: 'servicing',
        descricao: 'Descarregando',
      }, {
        id: 'completed',
        descricao: 'Entregue',
      }],
      statusPedido: [{
        id: 1,
        descricao: 'Ativo',
      }, {
        id: 2,
        descricao: 'Cancelado',
      }],
      deliveryType: [{
        id: 2,
        descricao: 'Entrega',
      }, {
        id: 1,
        descricao: 'Retira',
      }]
    },
  }),
  mounted () {
    this.pedido = this.value?.pedido
    this.notaFiscal = this.value?.notaFiscal
    this.empresaId = this.value?.empresaId
    this.producaoId = this.value?.producaoId
    this.tipoDataId = this.value?.tipoDataId
    this.dataInicial = this.value?.dataInicial
    this.dataFinal = this.value?.dataFinal
    this.statusIntegracaoId = this.value?.statusIntegracaoId
    this.statusRouteasyId = this.value?.statusRouteasyId
    this.statusPedidoId = this.value?.statusPedidoId
    this.deliveryTypeId = this.value?.deliveryTypeId
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    seachFilter () {
      this.change = false

      this.model = {
        pedido: this.pedido,
        notaFiscal: this.notaFiscal,
        empresaId: this.empresaId,
        producaoId: this.producaoId,
        tipoDataId: this.tipoDataId,
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal,
        statusIntegracaoId: this.statusIntegracaoId,
        statusRouteasyId: this.statusRouteasyId,
        statusPedidoId: this.statusPedidoId,
        deliveryTypeId: this.deliveryTypeId
      }
    },
  },
}
</script>
