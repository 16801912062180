<template>
  <div>
    <actions-listagem-routeasy-component v-model="headers" :filtro="filtro" :items-per-page="itemsPerPage" @itemsPerPage="itemsPerPage = $event"/>
    <filter-listagem-routeasy-component v-model="filtro"/>
    <visualiza-routeasy-components :filtros="filtro" :headers="headers" :items-per-page="itemsPerPage"/>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import FilterListagemRouteasyComponent from '@/view/pages/fretes/routeasy/components/FilterListagemRouteasyComponent'
import ActionsListagemRouteasyComponent from '@/view/pages/fretes/routeasy/components/ActionsListagemRouteasyComponent'
import VisualizaRouteasyComponents from '@/view/pages/fretes/routeasy/components/VisualizaRouteasyComponents'

export default {
  components: {
    VisualizaRouteasyComponents,
    ActionsListagemRouteasyComponent,
    FilterListagemRouteasyComponent,
  },

  data: (instance) => ({
    filtro: {
      pedido: instance.$route.query.pedido ? instance.$route.query.pedido : null,
      nota_fiscal: instance.$route.query.nota_fiscal ? instance.$route.query.nota_fiscal : null,
      empresaId: instance.$route.query.empresaId ? parseInt(instance.$route.query.empresaId) : null,
      producaoId: instance.$route.query.producaoId ? parseInt(instance.$route.query.producaoId) : null,
      tipoDataId: instance.$route.query.tipoDataId ? parseInt(instance.$route.query.tipoDataId) : 3,
      dataInicial: instance.$route.query.dataInicial ? instance.$route.query.dataInicial : instance.$date.addDays(null, 1),
      dataFinal: instance.$route.query.dataFinal ? instance.$route.query.dataFinal : instance.$date.addDays(null, 1),
      statusIntegracaoId: instance.$route.query.statusIntegracaoId ? parseInt(instance.$route.query.statusIntegracaoId) : null,
      statusRouteasyId: instance.$route.query.statusRouteasyId ? parseInt(instance.$route.query.statusRouteasyId) : null,
      statusPedidoId: instance.$route.query.statusPedidoId ? parseInt(instance.$route.query.statusPedidoId) : 1,
      deliveryTypeId: instance.$route.query.deliveryTypeId ? parseInt(instance.$route.query.deliveryTypeId) : 2,
    },
    headers: [],
    itemsPerPage: 25,
  }),

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Integração RoutEasy', route: 'routeasy' },
      { title: 'Painel RoutEasy' },
    ])
  },
}
</script>
